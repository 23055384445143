
import { defineComponent, inject, watch, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  props: {
    isHideBack: {
      default: false,
      type: Boolean,
    },
    isEmit: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["handle-click", "handle-headide"],
  setup(props, ctx) {
    const router = useRouter();
    const route = useRoute();
    // const hide = inject("handleHide");
    function back() {
      if (props.isEmit) {
        ctx.emit("handle-click");
      } else {
        if(window.history.length <= 1){
          router.push({path: "/"})
        }else{
          router.back();
        }

      }
    }
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    function toLogin() {
      if (!props.isEmit) {
        // redirect personal
        if (userInfo && userInfo.id) {


          // router.push({
          //   name: "Personal",
          //   query: {
          //     uid: userInfo.id,
          //   },
          // });
        } else {
          if(route.path != '/login'){
            // router.push({
            //   name: "Login",
            //   query: {
            //     redirect: route.fullPath
            //   }
            // })
            router.push({
              name: "Mine",
            })
          }else{
            // router.push({
            //   name: "Login"
            // })
            router.push({
              name: "Mine"
            })
          }

        }
      }
    }

    const show = inject("handleMenuHide");

    function menuShow() {
      (show as () => void)();
    }
    function onSearch(type: string) {
      ctx.emit("handle-headide")
      router.push({
        name:"Search",
        query:{ type }
      })
    }
    return {
      back,
      toLogin,
      menuShow,
      userInfo,
      onSearch
    };
  },
});
