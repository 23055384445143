
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from 'qs'
export default defineComponent({
  name: "Footer",
  setup() {
    const route = useRoute();
    const shopCarNum = ref()

    const name = ref(route.name);
    onMounted(() => {
      const guid = localStorage.getItem('guid')
      const data = qs.stringify({
          cartGUID: guid
      })
      axios.post('/M/Server/GetMyCartInfo', data)
        .then((res) => {
            if (res.data.success) {
              shopCarNum.value = res.data.id
            } else {
                Toast({
                    type: "error",
                    title: res.data.msg,
                });
            }
        })
        .catch((err) => {
            console.log('err');
        });
    })

    watch(
      () => route.name,
      () => {
        name.value = route.name;
      }
    );
    function chat() {
      window.open(
          "https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1",
          "_blank",
          "top=300,left=500,width=800,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
      );
      return false;
    }
    return {
      name,
      shopCarNum,
      chat
    };

  },
});
